exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-destination-js": () => import("./../../../src/templates/destination.js" /* webpackChunkName: "component---src-templates-destination-js" */),
  "component---src-templates-enquiry-js": () => import("./../../../src/templates/enquiry.js" /* webpackChunkName: "component---src-templates-enquiry-js" */),
  "component---src-templates-housebeach-js": () => import("./../../../src/templates/housebeach.js" /* webpackChunkName: "component---src-templates-housebeach-js" */),
  "component---src-templates-housewild-js": () => import("./../../../src/templates/housewild.js" /* webpackChunkName: "component---src-templates-housewild-js" */),
  "component---src-templates-itineraries-js": () => import("./../../../src/templates/itineraries.js" /* webpackChunkName: "component---src-templates-itineraries-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-thankyou-js": () => import("./../../../src/templates/thankyou.js" /* webpackChunkName: "component---src-templates-thankyou-js" */),
  "component---src-templates-wildhill-js": () => import("./../../../src/templates/wildhill.js" /* webpackChunkName: "component---src-templates-wildhill-js" */),
  "component---src-templates-wildhub-js": () => import("./../../../src/templates/wildhub.js" /* webpackChunkName: "component---src-templates-wildhub-js" */),
  "component---src-templates-wildvillas-js": () => import("./../../../src/templates/wildvillas.js" /* webpackChunkName: "component---src-templates-wildvillas-js" */)
}

